<script setup lang="ts">
import { Product } from '@shopware-pwa/types';

withDefaults(
  defineProps<{
    product?: Product;
  }>(),
  { product: undefined },
);

const { breadcrumbs } = useBreadcrumbs();
const { formatLink } = useInternationalization();
</script>
<template>
  <nav
    v-if="breadcrumbs && breadcrumbs.length > 0"
    class="s-gutter"
    aria-label="Breadcrumb"
    data-testid="breadcrumb"
  >
    <ol class="c-width-default my-8 flex flex-wrap items-center gap-x-2 text-small text-maas-typography-navigation-grey-normal">
      <li>
        <NuxtLink
          :to="formatLink('/')"
          class="underline underline-offset-3 hover:decoration-none"
        >
          {{ $t("home") }}
        </NuxtLink>
      </li>
      <li
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="breadcrumb.path"
        class="flex-inline items-center gap-x-2"
      >
        <span v-if="index < breadcrumbs.length">
          ›
        </span>
        <NuxtLink
          v-if="breadcrumb.path"
          :to="formatLink(breadcrumb.path)"
          class="underline underline-offset-3 hover:decoration-none"
        >
          {{ breadcrumb.name }}
        </NuxtLink>
        <span v-else>
          {{ breadcrumb.name }}
        </span>
      </li>
      <li 
        v-if="product"
        class="flex-inline items-center gap-x-2"
      >
        <span>
          ›
        </span>
        <NuxtLink
          v-if="product.seoUrls?.[0].seoPathInfo"
          :to="'/' + product.seoUrls?.[0].seoPathInfo"
          class="underline underline-offset-3 hover:decoration-none"
        >
          {{ product.translated?.name }}
        </NuxtLink>
        <span v-else-if="product.translated?.name">
          {{ product.translated?.name }}
        </span>
        <span v-else>
          Produktdetail 
        </span>
      </li>
    </ol>
  </nav>
</template>